import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import Fancybox from '../../../../shared/components/Fancybox';
import GlobalCard from '../../../../shared/components/GlobalCard';
import ImageHandler from '../../../../shared/components/ImageHandler';
import {generatePhotoUrl} from '../../../../shared/functions/generatePhotoUrl';
import {handleError} from '../../../../shared/functions/handleError';
import {ICleaning} from '../../../../shared/interfaces/ICleaning';
import {CleaningService} from '../../location-cleaning-list/service';
import {useTranslation} from 'react-i18next';
import {formatDate} from '../../../../shared/functions/formatDate';
import {EStatus} from '../../../../shared/enums/EStatus';
import StatusBadge from '../../../../shared/components/StatusBadge';
import {IUser} from '../../../../shared/interfaces/IUser';
import {UserService} from '../../users/service';
import {BASE_URL} from '../../../../utils/api/endpoints';
import {toast} from 'react-toastify';

export interface IUpdateCleaningState {
    status?: EStatus;
    clientIds?: null | number[];
}

const SingleCleaningUpdate = () => {
    const {id} = useParams();
    const [clientList, setClientList] = useState<IUser[]>([])
    const [cleaning, setCleaning] = useState<ICleaning | null>(null);
    const [updateState, setUpdateState] = useState<IUpdateCleaningState>({
        status: EStatus.PENDING,
        clientIds: null
    })
    const {t} = useTranslation();
    const navigate = useNavigate();


    const handleUpdateCleaning = async (cleaningId: number) => {
        await CleaningService.updateCleaning(updateState, cleaningId).then((response) => {

            console.log(response)
        }).catch((err) => handleError(err))
    }

    const handleDownloadImages = async (cleaningId: number) => {
        await CleaningService.downloadAllImages(cleaningId).then(response => {
            const cleaningZip = BASE_URL + response?.url;
            window.open(cleaningZip);
        }).catch(err => handleError(err));
    }

    useEffect(() => {
        if (!!id) {
            CleaningService.getCleaningById(id)
                .then(response => {
                    setCleaning(response);
                    setUpdateState(prev => ({
                        ...prev,
                        status: response?.status,
                        clientIds: response?.clients?.map(client => client?.id)
                    }) as unknown as IUpdateCleaningState)
                }).catch(err => handleError(err))
        }
    }, [id]);

    useEffect(() => {
        if (cleaning?.status === EStatus.COMPLETED) {
            UserService.getAllClients({page: 1, perPage: 99999999}).then(response => {
                setClientList(response?.data)
            }).catch(err => handleError(err));
        }
    }, [cleaning?.status])
    return (
        <Row className={'justify-content-center'}>
            <Col md={8}>
                <GlobalCard>
                    <Fancybox
                        options={{
                            Carousel: {
                                infinite: false,
                            },
                        }}
                    >
                        <Row>
                            {cleaning?.photos?.map(photo => {
                                return (
                                    <Col md={3} key={photo?.id} className={'mb-3'}>

                                        <a data-fancybox="gallery" href={generatePhotoUrl(photo?.url)}>
                                            <ImageHandler src={generatePhotoUrl(photo?.url)}
                                                          className={'w-100 h-150px img-cover card-border-radius cursor-pointer'}/>
                                        </a>

                                    </Col>
                                )
                            })}

                            {cleaning?.status === EStatus.COMPLETED &&
                                <Col md={12} className={'border-top border-bottom py-2 px-0'}>
                                    <select
                                        name="client"
                                        className="form-select"
                                        value={(updateState?.clientIds || []) as unknown as string[]}
                                        onChange={(e) => {
                                            const selectedOptions = Array.from(e.target.selectedOptions, option => Number(option.value));
                                            setUpdateState(prev => ({
                                                ...prev,
                                                clientIds: selectedOptions
                                            }));
                                        }}
                                        multiple
                                    >
                                        <option hidden>
                                            Client
                                        </option>
                                        {clientList?.map(client => {
                                            return (
                                                <option value={client?.id} key={client?.id}>
                                                    {client?.firstName} {client?.lastName}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </Col>
                            }


                            {cleaning?.status === EStatus.PENDING &&
                                <Col md={12} className={'border-top border-bottom py-2 px-0'}>
                                    <select name="client" className="form-select"
                                            value={updateState?.status}
                                            onChange={(e) => setUpdateState(prev => ({
                                                ...prev,
                                                status: e.target.value as EStatus
                                            }))}
                                    >
                                        <option hidden>
                                            Status
                                        </option>
                                        {Object.keys(EStatus)?.map(status => {
                                            return (
                                                <option value={status} key={status}>
                                                    {status}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </Col>}

                            <Col md={12} className="ps-0 border-bottom py-2">
                                <div>
                                    <span
                                        className="fw-bold"> {t('general.firstName') + ' & ' + t('general.lastName')}: </span>
                                    <span> {cleaning?.user?.firstName} {cleaning?.user?.lastName}</span>
                                </div>

                                <div>
                                    <span className="fw-bold"> {t('general.location')}: </span>
                                    <span> {cleaning?.location?.name}</span>
                                </div>

                                <div>
                                    <span className="fw-bold"> {t('general.street')}: </span>
                                    <span> {cleaning?.street}</span>
                                </div>

                                <div>
                                    <span className="fw-bold"> {t('general.createdAt')}: </span>
                                    <span> {formatDate(cleaning?.createdAt as string, 'DD.MM.YYYY - HH:mm')}</span>
                                </div>

                                <div>
                                    <span className="fw-bold"> {t('general.status')}: </span>
                                    <StatusBadge status={cleaning?.status as EStatus}/>
                                </div>
                            </Col>

                            {cleaning?.status === EStatus.COMPLETED &&
                                <Col md={12} className="px-0 mt-2">
                                    <Button variant="success" className="w-100 text-white"
                                            onClick={() => handleDownloadImages(Number(cleaning?.id))}
                                    > {t('buttons.download')} </Button>
                                </Col>

                            }

                            {[EStatus.PENDING, EStatus.COMPLETED].includes(cleaning?.status as EStatus) &&
                                <Col md={12} className="px-0 my-2">
                                    <Button variant="primary" className="w-100"
                                            onClick={() => {
                                                handleUpdateCleaning(Number(cleaning?.id));
                                                toast.success(t('general.successfullyUpdated'));
                                                navigate(-1);
                                            }}
                                    > {t('buttons.update')} </Button>
                                </Col>
                            }

                            <Col md={12} className="px-0 my-1">
                                <Button className="w-100" variant="outline-primary"
                                        onClick={() => navigate(-1)}> {t('buttons.cancel')} </Button>
                            </Col>

                        </Row>
                    </Fancybox>
                </GlobalCard>
            </Col>
        </Row>
    );
};

export default SingleCleaningUpdate;
