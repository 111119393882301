import {FormEvent, useEffect, useState} from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getInitialRoute} from '../../shared/functions/getInitialRoute';
import {handleError} from '../../shared/functions/handleError';
import {ILogin} from '../../shared/interfaces/ILogin';
import authSlice from '../../store/slices/auth.slice';
import {RootState} from '../../store/store';
import {AuthService} from './service';
import {VscEye, VscEyeClosed} from 'react-icons/vsc';

const initialState = {
    email: '',
    password: '',
}

export default function Login() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isAuthenticated, userData} = useSelector((state: RootState) => state.auth);
    const [showPassword, setShowPassword] = useState(false);
    const [loginData, setLoginData] = useState<ILogin>(initialState);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setLoginData((prev) => ({...prev, [name]: value}))
    }

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        await AuthService.loginHandler(loginData)
            .then(response => {
                // console.log(response, "response")
                localStorage.setItem('token', JSON.stringify(response?.token));
                localStorage.setItem('currentVersion', JSON.stringify(response?.app?.currentVersion));

                dispatch(authSlice.actions.setLoginState({
                    isAuthenticated: true,
                    userData: {
                        ...response?.user,
                        displayName: `${response?.user?.firstName} ${response?.user?.lastName}`
                    }
                }));

                navigate(getInitialRoute(response?.user?.role));
                toast.success(`Welcome back, ${response?.user?.firstName}`);
            }).catch(err => {
                console.log(err, 'err')
                handleError(err, t);
            });
    }

    useEffect(() => {
        if (isAuthenticated && userData?.role) {
            navigate(getInitialRoute(userData?.role));
        }
    }, [isAuthenticated, userData?.role, navigate])

    return (
        <div>
            <div className="container d-flex justify-content-center align-items-center  h-100vh">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Form onSubmit={onSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-center flex-column">

                                        <img src="/logo.png" className="w-25 mb-3" alt="Company logo"/>
                                        <h6 className="text-primary fw-bold">
                                            P.S. Facility Management GmbH
                                        </h6>
                                    </div>
                                    <h2 className="text-center">
                                        {t('login.signIn')}
                                    </h2>
                                    <Form.Control
                                        placeholder={t('general.email')}
                                        type="email"
                                        required
                                        name="email"
                                        onChange={changeHandler}
                                    />

                                    <div className="my-3">
                                        <Form.Group controlId="formBasicPassword">
                                            <InputGroup>
                                                <Form.Control
                                                    type={showPassword ? 'text' : 'password'}
                                                    required
                                                    name="password"
                                                    placeholder={t('general.password')}
                                                    onChange={changeHandler}/>

                                                <InputGroup.Text>
                                                    {!showPassword ?
                                                        <VscEye className="cursor-pointer" onClick={() => {
                                                            setShowPassword((prev) => !prev);
                                                        }
                                                        }/> : <VscEyeClosed className="cursor-pointer" onClick={() => {
                                                            setShowPassword((prev) => !prev);
                                                        }
                                                        }/>}
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                    </div>

                                    <Button className="w-100" type="submit">
                                        {t('login.signIn')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
